import {
	faCalculator,
	faChartLineUp,
	faFileInvoiceDollar,
	faTruckContainer,
	faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useGetAllShipmentMetaData, useMetaData } from '@uturn/api/v1';
import { useFetchOrganizationStatus } from '@uturn/api/v3';
import type { TFunction } from 'i18next';
import { usePostHog } from 'posthog-js/react';
import { Suspense, useEffect } from 'react';
import { AbacProvider } from 'react-abac';
import { RouterProvider, useLocale } from 'react-aria';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { LoadingSpinnerWithLogo } from './components';
import FinancialBlockBanner from './components/financial-block-banner';
import type { NavigationItemProps } from './components/header';
import { Header } from './components/header';
import { SearchProvider } from './components/search-provider';
import { MetaDataContext } from './context';
import { FinancialBlockProvider } from './context/financial-block-context';
import ErrorPage from './ErrorPage';
import { MeiliSearchProvider } from './meilisearch';
import { useCarrierStore } from './store/carrier';
import { useArchivedShipments } from './store/shipments/archived-shipment';
import { useAssignedShipmentStore } from './store/shipments/assigned-shipment';
import { useDraftedShipments } from './store/shipments/drafted-shipment';
import { usePublishedShipments } from './store/shipments/published-shipment';
import { Permission, rules } from '@uturn/portal/abac';
import { Usetiful } from '@uturn/portal/services';

const userNavigation = (
	orgId: number,
	t: TFunction<'translation', undefined>,
) => [
	{
		name: t('header.navigation.user.account', 'Account'),
		href: '/account',
	},
	{
		name: t('header.navigation.user.organisaton', 'Organisation'),
		href: `/org/${orgId}/settings`,
	},
];

const navigation = (
	t: TFunction<'translation', undefined>,
): NavigationItemProps[] => [
	{
		name: t('header.navigation.shipments', 'Shipments'),
		href: '/shipments',
		icon: faTruckContainer,
		preFetch: () => {
			usePublishedShipments.getState().fetch();
			useAssignedShipmentStore.getState().fetch();
			useDraftedShipments.getState().fetch();
			useArchivedShipments.getState().fetch();
		},
	},
	{
		name: t('header.navigation.price-calculator', 'Price calculator'),
		href: '/calculator',
		icon: faCalculator,
	},
	{
		name: t('header.navigation.invoices', 'Invoices'),
		href: '/invoices',
		icon: faFileInvoiceDollar,
		preFetch: () => {
			// useInvoiceStore.getState().fetch();
		},
	},
	{
		name: t('header.navigation.my-carriers', 'My Carriers'),
		href: '/carriers',
		icon: faUsers,
		preFetch: () => {
			useCarrierStore.getState().fetch();
		},
	},
	{
		name: t('header.navigation.lanes', 'Lanes'),
		href: '/lanes',
		icon: faUsers,
	},
	{
		name: t('header.navigation.dashboard', 'Data insight'),
		href: '/insights',
		icon: faChartLineUp,
		permission: Permission.METABASE_API,
	},
];

export default function AppLayout() {
	const { t } = useTranslation();

	const posthog = usePostHog();
	const navigate = useNavigate();

	const { data: metaData } = useMetaData({
		query: {
			refetchOnWindowFocus: false,
		},
	});

	const { data: shipmentMetaData } = useGetAllShipmentMetaData({
		query: {
			refetchOnWindowFocus: false,
		},
	});

	const { data: shipmentUnits } = useQuery({
		queryKey: ['shipmentUnits'],
		queryFn: async () => {
			const response = await fetch('/shipment-units.json');
			const data = await response.json();
			return data;
		},
		suspense: true,
		cacheTime: Infinity,
		staleTime: Infinity,
	});
	const orgId = metaData?.data?.user?.organisation?.id ?? 0;

	const { data: organisationStatus } = useFetchOrganizationStatus(orgId, {
		query: {
			enabled: !!orgId,
		},
	});

	const isFinancialBlocked =
		organisationStatus?.data.status?.code === 'FINANCIAL_BLOCK' || false;

	useEffect(() => {
		if (metaData?.data.user?.id === undefined) {
			return;
		}

		posthog.identify(metaData.data.user.id.toString(), {
			...metaData.data.user,
			orgName: metaData.data.user.organisation!.name,
			orgId: metaData.data.user.organisation!.id,
		});

		// Associate user with an organization group
		if (metaData.data.user.organisation?.id) {
			posthog.group(
				'organization',
				metaData.data.user.organisation.id.toString(),
				{
					group_name: metaData.data.user.organisation.name,
					group_id: metaData.data.user.organisation.id,
				},
			);

			// ✅ Capture Group-Level Properties
			posthog.capture('organization_associated', {
				group_type: 'organization',
				group_id: metaData.data.user.organisation.id,
				group_name: metaData.data.user.organisation.name,
			});
		}
		Usetiful.identify(metaData.data.user);
		Sentry.setUser({
			email: metaData.data.user.username,
			fullName: `${metaData.data.user.firstName ?? ''} ${
				metaData.data.user.surname ?? ''
			}`,
		});
	}, [metaData, posthog]);

	const { locale, direction } = useLocale();

	return (
		<FinancialBlockProvider isFinancialBlocked={isFinancialBlocked}>
			<MetaDataContext.Provider
				value={{
					metadata: metaData?.data,
					shipmentMetaData: shipmentMetaData?.data,
					shippingRequirementExceptions: ['ADR', 'GENSET'],
					shipmentUnits,
					shipmentMaxRoutes: 5,
				}}
			>
				<AbacProvider
					user={metaData?.data.user}
					roles={[metaData?.data.user?.role ?? '']}
					rules={rules}
				>
					<MeiliSearchProvider>
						<SearchProvider>
							<div className="h-full" lang={locale} dir={direction}>
								<RouterProvider navigate={navigate}>
									<Header
										userNavigation={navigation(t)}
										dropdownItems={userNavigation(
											metaData?.data?.user?.organisation?.id as number,
											t,
										)}
										user={{
											name: `${metaData?.data.user?.firstName} ${metaData?.data.user?.surname}`,
											role: metaData?.data.user?.role?.includes('ADMIN')
												? 'Admin'
												: 'User',
										}}
									/>
									<div className="m-auto px-4 lg:px-8 3xl:w-2/3 3xl:px-0">
										<Suspense fallback={<LoadingSpinnerWithLogo />}>
											{isFinancialBlocked && (
												<FinancialBlockBanner className="mt-4" />
											)}

											<ErrorBoundary FallbackComponent={ErrorPage}>
												<Outlet />
											</ErrorBoundary>
										</Suspense>
									</div>
								</RouterProvider>
							</div>
						</SearchProvider>
					</MeiliSearchProvider>
				</AbacProvider>
			</MetaDataContext.Provider>
		</FinancialBlockProvider>
	);
}
