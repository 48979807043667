import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { ShipmentStatus } from '@uturn/api/v1';
import {
	Button,
	Icon,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
	cn,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { useFinancialBlock } from '@uturn/portal/context/financial-block-context';

export type SideBarActionProps = {
	label: string | React.ReactNode;
	variant: 'default' | 'outline' | 'link';
	onClick: () => void;
	isLoading?: boolean;
	disabled?: boolean;
	options?: {
		buttonLoadingCaption?: string;
	};
	className?: string;
	shipmentStatus?: ShipmentStatus;
};

export function SideBarAction({
	label,
	variant,
	onClick,
	isLoading = false,
	disabled = false,
	options,
	className,
	shipmentStatus,
}: SideBarActionProps) {
	const { isFinancialBlocked } = useFinancialBlock();
	const { t } = useTranslation();

	return isFinancialBlocked && shipmentStatus === ShipmentStatus.CONCEPT ? (
		<TooltipProvider delayDuration={10}>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						variant={variant}
						onClick={undefined}
						disabled={disabled || isLoading}
						className="w-full opacity-50 cursor-not-allowed"
					>
						{isLoading ? (
							<>
								<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
								{options?.buttonLoadingCaption ?? 'Loading...'}
							</>
						) : (
							label
						)}
					</Button>
				</TooltipTrigger>
				<TooltipContent side="top" align="center" sideOffset={1}>
					<div className="max-w-[450px] text-red-500">
						{t(
							'pages.create-shipment.financially-block.description',
							'You are unable to publish shipments due to financial restrictions. Save the shipment as a Concept.',
						)}
					</div>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	) : (
		<Button
			variant={variant}
			onClick={onClick}
			disabled={disabled || isLoading}
			className={cn('w-full', className)}
		>
			{isLoading ? (
				<>
					<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
					{options?.buttonLoadingCaption ?? 'Loading...'}
				</>
			) : (
				label
			)}
		</Button>
	);
}

export type SideBarActionsProps = {
	shipmentStatus: ShipmentStatus;
	isPublishing: boolean;
	isSubmitting: boolean;
	isDirty: boolean;
	onUpdate: () => void;
	onPublish: () => void;
	onUnpublish: () => void;
	onCancel: () => void;
};

export function SideBarActions({
	shipmentStatus,
	isPublishing,
	isSubmitting,
	isDirty,
	onUpdate,
	onPublish,
	onUnpublish,
	onCancel,
}: SideBarActionsProps) {
	const { t } = useTranslation();

	const labels = {
		save: t(
			'pages.shipment-detail.sticky_bar.save_concept_button',
			'Save concept',
		),
		publish: t(
			'pages.shipment-detail.sidebar.publish_shipment_button',
			'Publish shipment',
		),
		publishing: t(
			'pages.shipment-detail.sidebar.publishing_shipment_button',
			'Publishing shipment...',
		),
		update: t('pages.shipment-detail.sidebar.update_shipment_button', 'Update'),
		updating: t('pages.shipment-detail.sidebar.button.loading', 'Updating...'),
		cancel: t(
			'pages.shipment-detail.sidebar.cancel_shipment_button',
			'Cancel shipment',
		),
		unpublish: t(
			'pages.shipment-detail.sidebar.unpublish_shipment_button',
			'Unpublish shipment',
		),
	};

	const options = {
		buttonLoadingCaption: labels.updating,
	};

	if (shipmentStatus === ShipmentStatus.UNKNOWN) {
		return null;
	}
	return (
		<div className="flex flex-col items-center gap-2">
			{shipmentStatus === ShipmentStatus.CONCEPT && (
				<>
					<SideBarAction
						label={labels.publish}
						variant={'default'}
						onClick={onPublish}
						isLoading={isPublishing && isSubmitting}
						disabled={isSubmitting}
						options={{ buttonLoadingCaption: labels.publishing }}
						shipmentStatus={shipmentStatus}
					/>

					<span className="text-sm text-muted-foreground">
						{t('pages.create_shipment.sidebar.or_separator', 'or')}
					</span>

					<SideBarAction
						label={labels.save}
						variant={'link'}
						onClick={onUpdate}
						isLoading={!isPublishing && isSubmitting}
						disabled={isSubmitting || !isDirty}
						options={options}
						className="p-0 h-auto"
					/>
				</>
			)}
			{shipmentStatus === ShipmentStatus.PUBLISHED && (
				<>
					<SideBarAction
						label={labels.update}
						variant={'default'}
						onClick={onUpdate}
						isLoading={isSubmitting}
						options={options}
						disabled={!isDirty}
					/>
					<span className="text-sm text-muted-foreground">
						{t('pages.create_shipment.sidebar.or_separator', 'or')}
					</span>
					<SideBarAction
						label={labels.unpublish}
						variant={'link'}
						onClick={onUnpublish}
						disabled={isSubmitting}
						options={options}
						className="text-red-500 p-0 h-auto"
					/>
				</>
			)}
			{shipmentStatus === ShipmentStatus.CARRIER_ASSIGNED && (
				<>
					<SideBarAction
						label={labels.update}
						variant={'default'}
						onClick={onUpdate}
						isLoading={isSubmitting}
						disabled={!isDirty}
						options={options}
					/>

					<span className="text-sm text-muted-foreground">
						{t('pages.create_shipment.sidebar.or_separator', 'or')}
					</span>

					<SideBarAction
						label={labels.cancel}
						variant={'link'}
						onClick={onCancel}
						disabled={isSubmitting}
						options={options}
						className="text-red-500 p-0 h-auto"
					/>
				</>
			)}
			{shipmentStatus === ShipmentStatus.CANCELLED && (
				<SideBarAction
					label={labels.update}
					variant={'default'}
					onClick={onUpdate}
					isLoading={isSubmitting || !isDirty}
					options={options}
					disabled={!isDirty}
				/>
			)}
			{shipmentStatus === ShipmentStatus.COMPLETED && (
				<SideBarAction
					label={labels.update}
					variant={'default'}
					onClick={onUpdate}
					isLoading={isSubmitting}
					options={options}
					disabled={!isDirty}
				/>
			)}
			{shipmentStatus === ShipmentStatus.QUOTED && (
				<>
					<SideBarAction
						label={labels.update}
						variant={'default'}
						onClick={onUpdate}
						isLoading={isSubmitting}
						options={options}
						disabled={!isDirty}
					/>
					<span className="text-sm text-muted-foreground">
						{t('pages.create_shipment.sidebar.or_separator', 'or')}
					</span>
					<SideBarAction
						label={labels.unpublish}
						variant={'link'}
						onClick={onUnpublish}
						disabled={isSubmitting}
						options={options}
						className="text-red-500 p-0 h-auto"
					/>
				</>
			)}
			{shipmentStatus === ShipmentStatus.EXECUTED && (
				<SideBarAction
					label={labels.update}
					variant={'default'}
					onClick={onUpdate}
					isLoading={isSubmitting}
					options={options}
					disabled={!isDirty}
				/>
			)}
		</div>
	);
}
