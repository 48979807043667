import React, { createContext, useContext } from 'react';

type FinancialBlockContextType = {
	isFinancialBlocked: boolean;
};

const FinancialBlockContext = createContext<
	FinancialBlockContextType | undefined
>(undefined);

export const FinancialBlockProvider: React.FC<{
	isFinancialBlocked: boolean;
	children: React.ReactNode;
}> = ({ isFinancialBlocked, children }) => {
	return (
		<FinancialBlockContext.Provider value={{ isFinancialBlocked }}>
			{children}
		</FinancialBlockContext.Provider>
	);
};

export const useFinancialBlock = () => {
	const context = useContext(FinancialBlockContext);
	if (context === undefined) {
		throw new Error(
			'useFinancialBlock must be used within a FinancialBlockProvider',
		);
	}
	return context;
};
