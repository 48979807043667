import { useUpdateExtraCostByShipper } from '@uturn/api/v1';
import type { ShipperExtraCostResponse } from '@uturn/api/v1';

import { Button, Input, Label, sonner } from '@uturn/ui-kit';

import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function AdditionalCostAction({
	additionalCostId,
	invalidateQueries,
}: {
	additionalCostId: number;
	invalidateQueries: () => void;
}) {
	const [hasError, setHasError] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation();

	const { mutate, isLoading } = useUpdateExtraCostByShipper({
		mutation: {
			onSuccess: () => {
				invalidateQueries();
				sonner.success(
					t(
						'pages.shipment-detail.sheet.financials.form.submit.success.description',
						'The additional cost has been updated successfully.',
					),
				);
			},
		},
	});

	return (
		<div className="flex w-full flex-col gap-2">
			<hr />
			<div className="flex w-full flex-col gap-2 pt-4">
				<Label>
					{t(
						'pages.shipment-detail.sheet.financials.form.comment.label',
						'Comment',
					)}
				</Label>
				<Input ref={inputRef} />
				{hasError && (
					<span className="text-destructive">
						{t(
							'pages.shipment-detail.sheet.financials.form.comment.validation',
							'A comment is required for declining',
						)}
					</span>
				)}
			</div>
			<div className="grid grid-flow-col gap-2">
				<Button
					disabled={isLoading}
					onClick={() => {
						const data: ShipperExtraCostResponse = {
							id: additionalCostId,
							code: 'APPROVED',
						};

						if (inputRef.current?.value !== '') {
							data.shipperComment = inputRef.current?.value;
						}

						mutate({ data });
					}}
				>
					{t('general.approve', 'Approve')}
				</Button>
				<Button
					variant="secondary"
					disabled={isLoading}
					onClick={() => {
						if (inputRef.current?.value === '') {
							setHasError(true);
						} else {
							mutate({
								data: {
									id: additionalCostId,
									code: 'DECLINED',
									shipperComment: inputRef.current?.value,
								},
							});
						}
					}}
				>
					{t('general.decline', 'Decline')}
				</Button>
			</div>
		</div>
	);
}
