import { RouteType, useCreateOrPublishMultipleShipments } from '@uturn/api/v2';
import type { AgGridReact } from '@uturn/ui-kit';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	Button,
	Input,
	Separator,
	sonner,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@uturn/ui-kit';
import dayjs from 'dayjs';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useBlocker, useNavigate, useSearchParams } from 'react-router-dom';
import MultiCreateTable from '../../components/multi-create-table';
import {
	schema,
	transformBack,
} from '../../components/multi-create-table/types/schema';
import { Page } from '@uturn/portal/components';
import Overlay from '@uturn/portal/components/overlay';
import { useFinancialBlock } from '@uturn/portal/context/financial-block-context';
import type { TransportTypeCode } from '@uturn/portal/types/shipment';

const FF_SHIPMENT_BULK_CREATE = 'shipment_bulk_create';
const FF_GROUP_NAME = 'shipment_bulk_create_group_name';

const arrayifyLocations = (data: any) => {
	return Object.entries(data).reduce(
		(acc, [key, value]) => {
			// Check if the key starts with "location_" and is followed by a number
			const match = key.match(/^location_(\d+)_(.+)$/);

			if (match) {
				// Extract the numeric part and the property name
				const [, index, property] = match;

				// Ensure the correct index in the array (convert index to 0-based)
				const locationIndex = parseInt(index, 10) - 1;
				if (!acc.locations[locationIndex]) {
					acc.locations[locationIndex] = {};
				}

				// Assign the value to the corresponding property in the grouped object
				acc.locations[locationIndex][property] = value;
			} else {
				// If the key does not match the "location_" pattern, add it directly to acc
				acc[key] = value;
			}

			return acc;
		},
		{ locations: [] },
	);
};

const Bulk = () => {
	const [groupName, setGroupName] = useState<string>('');
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate, isLoading } = useCreateOrPublishMultipleShipments();
	const shipmentBulkCreateEnabled = useFeatureFlagEnabled(
		FF_SHIPMENT_BULK_CREATE,
	);
	const groupNameEnabled = useFeatureFlagEnabled(FF_GROUP_NAME);
	const ref = useRef<AgGridReact>(null);
	const blocker = useBlocker(
		({ currentLocation, nextLocation, historyAction }) => {
			// Temporary fix until we move to a proper modal after succesfull publish
			if (historyAction === 'REPLACE') {
				return false;
			}

			// TODO: When intregating schemas and the correct types, this needs to be typed properly a swell
			const data = [];
			ref.current?.api.forEachNode((node) => data.push(node.data));
			const isDirty = data.some((row) => row.dirty);

			return isDirty && currentLocation.pathname !== nextLocation.pathname;
		},
	);

	const { isFinancialBlocked } = useFinancialBlock();

	if (!shipmentBulkCreateEnabled) {
		navigate('/shipments/add');

		return null;
	}

	const onClickHandler = (publish = true) => {
		const data = [];
		let hasError = false;

		ref.current?.api.forEachNode((node) => {
			if (node.data?.dirty) {
				const transformedData = transformBack(node.data);
				const transformedDataWithArrifiedLocations =
					arrayifyLocations(transformedData);

				const { success, error } = schema.safeParse(
					transformedDataWithArrifiedLocations,
				);

				if (success) {
					data.push(transformedDataWithArrifiedLocations);

					return;
				}

				hasError = true;

				const initial: Record<string, any> = {};
				const result = error.issues.reduce((acc, { path, message }) => {
					// TODO: revisit this, this is not future proof
					let index = path[0];
					if (path[0] === 'locations') {
						index = `location_${path[1] + 1}_${path[2]}`;
					}

					acc[index] = {
						...node.data[index],
						isValid: false,
						errorMessage: message,
					};
					return acc;
				}, initial);

				ref.current?.api.applyTransaction({
					update: [
						{
							...node.data,
							...result,
						},
					],
				});
			}
		});

		if (hasError) {
			sonner.error(t('toast.error-title', 'Uh oh! Something went wrong.')!, {
				description: t(
					'general.required-fields',
					'One or more fields have errors, please correct them before submitting',
				),
			});

			ref.current?.api.redrawRows();
			return;
		}

		if (data.length > 0) {
			mutate(
				{
					data: data.map((row) => ({
						cargoDescription: row.cargoDescription,
						concept: !publish,
						conditions: row.termsAndConditions.label, // TODO: fix this
						equipment: {
							containerNumber: row.containerNumber,
							isoType: row.isoCode,
							shipmentUnitId:
								row.containerType && Number(row.containerType.value),
						},
						fixedPrice: !row.bidOnly,
						generatorSet: row.generatorSet,
						grossWeight: {
							quantity: row.cargoGrossWeight.quantity,
							unit: row.cargoGrossWeight.unit,
						},
						locationActions: row.locations.map((location, index) => ({
							dateFrom: dayjs(location.from).tz().format(),
							dateUntil: dayjs(location.until).tz().format(),
							location: {
								...(location.location.id
									? { id: location.location.id }
									: { googlePlaceId: location.location.placeId }),
							},
							locationActionType: location.type.value
								.replace('-', '')
								.toUpperCase(), // TODO: fix this
							port: location.port,
							reference: location.reference,
							sequenceNumber: index + 1,
							shippingLineId:
								location.shippingLine && Number(location.shippingLine.value),
							vesselName: location.vesselName,
						})),
						numberOfCopiesToBeCreated: 1,
						preferredCarriersIds: row.preferredCarriers?.map(
							(preferredCarrier) => Number(preferredCarrier.value),
						),
						price: {
							quantity: row.price.quantity,
							currency: row.price.currency,
						},
						publishUntilDate: dayjs(row.locations[0].until).tz().format(),
						requirementIds: row.requirements?.map((requirement) =>
							Number(requirement.value),
						),
						routeKpis: [
							{
								isSelected: true,
								routeType: RouteType.TRUCK,
							},
						],
						seal: row.seal,
						shipperReference: row.invoiceReference,
						shippingLineId: row.shippingLine && Number(row.shippingLine.value),
						unCode: row.unCode,
						dutiesPaid: row.vat ?? false,
						...(groupName !== '' && { newGroup: { name: groupName } }),
					})),
				},
				{
					onSuccess: () => {
						sonner.success(
							t(
								`pages.bulk_create_shipments.title.message.success`,
								'Your shipment(s) have been successfully created',
							),
						);

						if (groupName !== '') {
							navigate(`/shipments/groups`, { replace: true });
							return;
						}
						if (publish) {
							navigate('/shipments', { replace: true });
							return;
						}
						navigate('/shipments/drafted', { replace: true });
					},
					onError: () => {
						sonner.error(
							t('toast.error-title', 'Uh oh! Something went wrong.')!,
							{
								description: t(
									'general.try-again-later',
									'An unknown error occured, please contact customer service',
								),
							},
						);
					},
				},
			);
		}
	};

	const transportType =
		(searchParams.get('transportType') as TransportTypeCode | undefined) ||
		undefined;

	return (
		<>
			<Helmet
				title={t('pages.bulk_create_shipments.title', 'Bulk create shipments')!}
			/>
			{isLoading && <Overlay>{t('general.loading', 'Loading...')!}</Overlay>}
			<Page
				title={t('pages.bulk_create_shipments.title', 'Bulk create shipments')!}
				backButton={{
					label: t(
						'pages.bulk_create_shipments.back_button',
						'Add new shipment',
					),
					href: '/shipments/add',
				}}
				stickyBar={{
					position: 'bottom',
					children: (
						<div className="flex items-center justify-end gap-5">
							{groupNameEnabled && (
								<>
									<Input
										className="max-w-[300px]"
										placeholder="Group name (optional)"
										value={groupName}
										onChange={(e) => setGroupName(e.target.value)}
									/>
									<Separator orientation="vertical" className="h-5" />
								</>
							)}
							<Button
								variant="link"
								className="p-0"
								onClick={() => onClickHandler(false)}
							>
								{t('shipments.bulk.concept', 'Save as concept')}
							</Button>
							{isFinancialBlocked ? (
								<TooltipProvider delayDuration={10}>
									<Tooltip>
										<TooltipTrigger asChild>
											<Button
												className="min-w-[120px] opacity-50 cursor-not-allowed"
												onClick={undefined}
											>
												{t('shipments.bulk.publish', 'Publish')}
											</Button>
										</TooltipTrigger>
										<TooltipContent side="top" align="center" sideOffset={1}>
											<div className="max-w-[450px] text-red-500">
												{t(
													'pages.create-shipment.financially-block.description',
													'You are unable to publish shipments due to financial restrictions. Save the shipment as a Concept.',
												)}
											</div>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : (
								<Button
									className="min-w-[120px]"
									onClick={() => onClickHandler(true)}
								>
									{t('shipments.bulk.publish', 'Publish')}
								</Button>
							)}
						</div>
					),
				}}
			>
				<MultiCreateTable ref={ref} transportType={transportType} />
				{blocker.state === 'blocked' && (
					<AlertDialog open={true}>
						<AlertDialogContent>
							<AlertDialogHeader>
								<AlertDialogTitle>
									{t(
										'dialog.blocker.title',
										'Are you sure you want to leave this page?',
									)}
								</AlertDialogTitle>
								<AlertDialogDescription>
									{t(
										'dialog.blocker.description',
										'You have unsaved changes. Are you sure you want to leave this page? Any changes you have made will be lost.',
									)}
								</AlertDialogDescription>
							</AlertDialogHeader>
							<AlertDialogFooter>
								<AlertDialogCancel onClick={() => blocker.reset()}>
									{t('dialog.blocker.cancel-button', 'Stay')}
								</AlertDialogCancel>
								<AlertDialogAction onClick={() => blocker.proceed()}>
									{t('dialog.blocker.confirm-button', 'Leave')}
								</AlertDialogAction>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialog>
				)}
			</Page>
		</>
	);
};

export default Bulk;
