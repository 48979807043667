import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import {
	Button,
	Icon,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { useFinancialBlock } from '@uturn/portal/context/financial-block-context';

export type SideBarActionsProps = {
	sendingShipmentData?: boolean;
	publishShipment: () => void;
	saveAsConcept: () => void;
	isConcept: boolean;
	totalPrice?: string;
	numberOfShipments?: number;
	isGroupedShipment?: boolean;
	isDirty?: boolean;
};

export function SideBarActions({
	sendingShipmentData = false,
	publishShipment,
	saveAsConcept,
	isConcept,
	numberOfShipments = 1,
	isGroupedShipment = false,
	isDirty,
	totalPrice,
}: SideBarActionsProps) {
	const { t } = useTranslation();
	const { isFinancialBlocked } = useFinancialBlock();

	const labels = {
		save: t(
			'pages.create_shipment.sidebar.save_concept_button',
			'Save as concept',
		),
		saving: t(
			'pages.create_shipment.sidebar.saving_concept_button',
			'Saving...',
		),
		publish: isGroupedShipment
			? t(
					'pages.create_shipment.sidebar.publish_group_shipment_button',
					'Publish {{numberOfShipments}} shipment(s) - {{totalPrice}}/container',
					{ totalPrice, numberOfShipments },
				)
			: t(
					'pages.create_shipment.sidebar.publish_shipment_button',
					'Publish shipment',
				),
		publishing: isGroupedShipment
			? t(
					'pages.create_shipment.sidebar.publishing_group_shipment_button',
					'Publishing {{numberOfShipments}} shipment(s)...',
					{ numberOfShipments },
				)
			: t(
					'pages.create_shipment.sidebar.publishing_shipment_button',
					'Publishing shipment',
				),
	};

	return (
		<div className="flex flex-col items-center">
			{isFinancialBlocked ? (
				<TooltipProvider delayDuration={10}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="default"
								onClick={undefined}
								className="w-full opacity-50 cursor-not-allowed"
							>
								{!isConcept && sendingShipmentData ? (
									<>
										<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
										{labels.publishing}
									</>
								) : (
									labels.publish
								)}
							</Button>
						</TooltipTrigger>
						<TooltipContent side="top" align="center" sideOffset={1}>
							<div className="max-w-[450px] text-red-500">
								{t(
									'pages.create-shipment.financially-block.description',
									'You are unable to publish shipments due to financial restrictions. Save the shipment as a Concept.',
								)}
							</div>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			) : (
				<Button
					variant="default"
					onClick={publishShipment}
					disabled={sendingShipmentData || !isDirty}
					className="w-full"
				>
					{!isConcept && sendingShipmentData ? (
						<>
							<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
							{labels.publishing}
						</>
					) : (
						labels.publish
					)}
				</Button>
			)}

			<span className="text-sm text-muted-foreground">
				{t('pages.create_shipment.sidebar.or_separator', 'or')}
			</span>

			<Button
				variant="link"
				onClick={saveAsConcept}
				disabled={sendingShipmentData || !isDirty}
				className="p-0 h-auto"
			>
				{isConcept && sendingShipmentData ? (
					<>
						<Icon className="mr-2 size-4" icon={faSpinnerThird} spin />
						{labels.saving}
					</>
				) : (
					labels.save
				)}
			</Button>
		</div>
	);
}
