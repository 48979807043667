import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { Callout, CalloutIcon, CalloutTitle, Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

const FinancialBlockBanner: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
	const { t } = useTranslation();

	return (
		<Callout variant="destructive" {...props}>
			<CalloutIcon>
				<Icon icon={faInfoCircle} />
			</CalloutIcon>
			<CalloutTitle>
				{t(
					'pages.create-shipment.financially-block.description',
					'You are unable to publish shipments due to financial restrictions. Please contact your finance department for further details. You can save the shipment as a Concept.',
				)}
			</CalloutTitle>
		</Callout>
	);
};

export default FinancialBlockBanner;
